import { useState, useEffect, useRef, useMemo } from 'react';
import { DokyoIcon } from '../../icons'
import './index.css';

const origin = window.location.origin;

const allVideoImages = Array(82).fill('').map((_, i) => {
    return `${origin}/videoimages/Vending_machine_0${i > 9 ? i : '0' + i}.jpg`
})

const isIos = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);

const Home = () => {
    return isIos ? <HomeVideoImages /> : <HomeVideo />
}

const HomeVideo = () => {
    const [loaded, setLoaded] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener("canplay", () => {
                setTimeout(() => {
                    setLoaded(true);
                }, 1000)
            });
        }
    }, [videoRef]);

    return (
        <div className="dokyo-app" data-loaded={loaded}>
            <div className="dokyo-app-header_logo">
                <DokyoIcon />
            </div>
            <div className='dokyo-app-video'>
                <video playsInline id="video" autoPlay loop preload="none" muted ref={videoRef}>
                    <source type="video/mp4" src={`${origin}/videos/Vending_machine.mp4`} />
                </video>
            </div>
        </div>
    )
}

const HomeVideoImages = () => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const recordLoadImage = useMemo(() => {
        let imgCount = 0;

        return () => {
            imgCount = imgCount + 1;

            if (imgCount === 82) {
                setImageLoaded(true);
            }
        }
    }, [])

    useEffect(() => {
        let interId: NodeJS.Timer;
        if (imageLoaded) {
            interId = setInterval(() => {
                setCurrentImage(c => c === 81 ? 0 : c + 1);
            }, 67)
        }

        return () => {
            if (interId) {
                clearInterval(interId);
            }
        }
    }, [imageLoaded])

    return (
        <div className="dokyo-app" data-loaded={imageLoaded}>
            <div className="dokyo-app-header_logo">
                <DokyoIcon />
            </div>
            <div className='dokyo-app-video'>
                {allVideoImages.map((source, i) => <img key={source} src={source} alt="" data-show={i === currentImage} onLoad={recordLoadImage}/>)}
            </div>
        </div>
    )
}

export default Home;